<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='订单号' prop='orderid' :label-width="formLabelWidth">
              <el-input placeholder='请输入订单号' clearable autocomplete="off" v-model='form.orderid' size="small"  />
            </el-form-item>
            <el-form-item label='商品id' prop='goodsid' :label-width="formLabelWidth">
              <el-input placeholder='请输入商品id' clearable autocomplete="off" v-model='form.goodsid' size="small"  />
            </el-form-item>
            <el-form-item label='商品套餐id' prop='skuid' :label-width="formLabelWidth">
              <el-input placeholder='请输入商品套餐id' clearable autocomplete="off" v-model='form.skuid' size="small"  />
            </el-form-item>
            <el-form-item label='自己id' prop='reuserid' :label-width="formLabelWidth">
              <el-input placeholder='请输入自己id' clearable autocomplete="off" v-model='form.reuserid' size="small"  />
            </el-form-item>
            <el-form-item label='自己邮箱' prop='email' :label-width="formLabelWidth">
              <el-input placeholder='请输入自己邮箱' clearable autocomplete="off" v-model='form.email' size="small"  />
            </el-form-item>
            <el-form-item label='用户id' prop='userid' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户id' clearable autocomplete="off" v-model='form.userid' size="small"  />
            </el-form-item>
            <el-form-item label='用户邮箱' prop='useremail' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户邮箱' clearable autocomplete="off" v-model='form.useremail' size="small"  />
            </el-form-item>
            <el-form-item label='推广类型' prop='type' :label-width="formLabelWidth">
              <el-input placeholder='请输入推广类型' clearable autocomplete="off" v-model='form.type' size="small"  />
            </el-form-item>
            <el-form-item label='订单金额' prop='price' :label-width="formLabelWidth">
              <el-input placeholder='请输入订单金额' clearable autocomplete="off" v-model='form.price' size="small"  />
            </el-form-item>
            <el-form-item label='收益' prop='profit' :label-width="formLabelWidth">
              <el-input placeholder='请输入收益' clearable autocomplete="off" v-model='form.profit' size="small"  />
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertCommission,updateCommission,deleteCommission,bacthDelCommission } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const typeOptions=[
          {
              value:"1",
              label:"推广链接"
          },
          {
              value:"2",
              label:"折扣码"
          },
      ]
        const statusOptions=[
            {
                value:"1",
                label:"正常"
            },
            {
                value:"0",
                label:"无效"
            },
        ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'orderid',label: '订单号'},
          {prop: 'goodsid',label: '商品id'},
          {prop: 'skuid',label: '商品套餐id'},
          {prop: 'reuserid',label: '自己id'},
          {prop: 'email',label: '自己邮箱'},
          {prop: 'userid',label: '用户id'},
          {prop: 'useremail',label: '用户邮箱'},
          {prop: 'type',label: '推广类型',formatter: row => this.typeState(row)},
          {prop: 'status',label: '佣金状态',formatter: row => this.statusState(row)},
          {prop: 'price',label: '订单金额'},
          {prop: 'profit',label: '收益'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
            type: 'input',
            id: 'orderid',
            label: '订单号',
            el: {placeholder: '请输入订单号', clearable: true},
          },
          {
            type: 'input',
            id: 'goodsid',
            label: '商品id',
            el: {placeholder: '请输入商品id', clearable: true},
          },
          {
            type: 'input',
            id: 'skuid',
            label: '商品套餐id',
            el: {placeholder: '请输入商品套餐id', clearable: true},
          },
          {
            type: 'input',
            id: 'reuserid',
            label: '自己id',
            el: {placeholder: '请输入自己id', clearable: true},
          },
          {
            type: 'input',
            id: 'email',
            label: '自己邮箱',
            el: {placeholder: '请输入自己邮箱', clearable: true},
          },
          {
            type: 'input',
            id: 'userid',
            label: '用户id',
            el: {placeholder: '请输入用户id', clearable: true},
          },
          {
            type: 'input',
            id: 'useremail',
            label: '用户邮箱',
            el: {placeholder: '请输入用户邮箱', clearable: true},
          },
          {
            type: 'select',
            id: 'type',
            label: '推广类型',
            el: {placeholder: '请输入推广类型', clearable: true},
            options:typeOptions
          },
      ]
      return {
        columns,
        typeOptions,
        statusOptions,
        userinfo:{},
        tableConfig: {
          searchForm,
          url: 'commission/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('commission:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('commission:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('commission:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('commission:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            orderid :[
              {required: true, message: '请输入订单号', trigger: 'blur'},],
            goodsid :[
              {required: true, message: '请输入商品id', trigger: 'blur'},],
            skuid :[
              {required: true, message: '请输入商品套餐id', trigger: 'blur'},],
            reuserid :[
              {required: true, message: '请输入自己id', trigger: 'blur'},],
            email :[
              {required: true, message: '请输入自己邮箱', trigger: 'blur'},],
            userid :[
              {required: true, message: '请输入用户id', trigger: 'blur'},],
            useremail :[
              {required: true, message: '请输入用户邮箱', trigger: 'blur'},],
            type :[
              {required: true, message: '请输入推广类型', trigger: 'blur'},],
            price :[
              {required: true, message: '请输入订单金额', trigger: 'blur'},],
            profit :[
              {required: true, message: '请输入收益', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
        typeState(row){
            let data
            this.typeOptions.forEach(r=>{
                if (r.value==row.type){
                    data=r.label
                }
            })
            return data
        },
        statusState(row){
            let data
            this.statusOptions.forEach(r=>{
                if (r.value==row.status){
                    data=r.label
                }
            })
            return data
        },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          orderid : "", // 订单号
          goodsid : "", // 商品id
          skuid : "", // 商品套餐id
          reuserid : "", // 自己id
          email : "", // 自己邮箱
          userid : "", // 用户id
          useremail : "", // 用户邮箱
          type : "", // 推广类型
          price : "", // 订单金额
          profit : "", // 收益
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteCommission(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelCommission(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertCommission(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateCommission(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token')
          }
        }
        if (!this.hasRole('commission:update')&&!this.hasRole('commission:delete')){
          this.tableConfig.hasOperation=false
        }
      }
    }
  }
</script>

<style scoped>

</style>
